import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import Footer from "../components/footer/footer.component"
import arrow from "../images/arrow_left.svg"
import Header from "../components/header/header.component"
import SEO from "../components/seo"
import "../styles.css"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MM.DD.YYYY")
        path
        author_post
        authorimage {
          publicURL
        }
        blogtitle
        author
        categories
        thumbnail {
          publicURL
        }
      }
    }
  }
`

const blog = props => {
  return (
    <>
      <SEO title={props.data.markdownRemark.frontmatter.blogtitle} />
      <Header />
      <div style={{ background: "#FFFFFF" }} id="top">
        <div className="resource_head blog_page"></div>
        <div className="container">
          <Link to="/resources" style={{ padding: "0px 0px 0px 0px" }}>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#2C4888",
                textDecoration: "none",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <span
                style={{
                  verticalAlign: "middle",
                  margin: "0 auto",
                  display: "inline-flex",
                  paddingRight: "5px",
                }}
              >
                <img src={arrow} alt="left arrow" />
              </span>
              <span className="ourtext">
                Show all Resources{" "}
              </span>
            </div>
          </Link>
         </div>
       <div className="container"
            style={{
              width: "650px",
              }}
         >         
          <div className="blogpost-header">
            <div className="blogpost-main">
              <div className="meta">
                <div className="date">
                  {props.data.markdownRemark.frontmatter.date}
                </div>{" "}
              </div>
              <h1>
                <span className="ourtitle">
                  {props.data.markdownRemark.frontmatter.blogtitle}
                </span>
              </h1>
              <hr />
              <div className="author">
                <div className="author_image">
                  <img
                    src={
                      props.data.markdownRemark.frontmatter.authorimage
                        .publicURL
                    }
                    alt="author"
                  />
                </div>
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                      paddingRight: "2px",
                           paddingLeft: "10px",
                    }}
                  >
                    <span className="ourtext">
                      {props.data.markdownRemark.frontmatter.author}
                    </span>
                  </div>
                 <div
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                  >
                   <span className="ourtext">
                     |
                   </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "18px",
                      paddingLeft: "2px",
                    }}
                  >
                    <span className="ourtext">
                      {props.data.markdownRemark.frontmatter.author_post}
                    </span>
                  </div>
              </div>
              <div className="blog_page_image" style={{ borderRadius: "5px" }}>
                <img
                  src={
                    props.data.markdownRemark.frontmatter.thumbnail.publicURL
                  }
                  alt="blog "
                />
              </div>
            </div>
         </div>
         <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{
                  __html: props.data.markdownRemark.html,
                }}
                 style={{
                        fontSize: "17px",                      
                        lineHeight: "25.5px",
                    }}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default blog
